import "././node_modules/bootstrap/dist/css/bootstrap.css"
import ReactDOM from "react-dom/client"

// gatsby-browser.js
if (typeof window !== 'undefined') {
  window.__REACT_DEVTOOLS_GLOBAL_HOOK__ = window.__REACT_DEVTOOLS_GLOBAL_HOOK__ || {};
  window.__REACT_DEVTOOLS_GLOBAL_HOOK__.recordChangeDescriptions = true;
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  window.scrollTo(0, 0);
};
export const replaceHydrateFunction = () => {

  return (element, container) => {
      const root = ReactDOM.createRoot(container)
      root.render(element)
  }
}