exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-condition-js": () => import("./../../../src/pages/terms-and-condition.js" /* webpackChunkName: "component---src-pages-terms-and-condition-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-yachtfishingdubai-js": () => import("./../../../src/pages/yachtfishingdubai.js" /* webpackChunkName: "component---src-pages-yachtfishingdubai-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-luxury-yachts-js": () => import("./../../../src/templates/luxury-yachts.js" /* webpackChunkName: "component---src-templates-luxury-yachts-js" */),
  "component---src-templates-party-yacht-js": () => import("./../../../src/templates/party-yacht.js" /* webpackChunkName: "component---src-templates-party-yacht-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-yacht-details-js": () => import("./../../../src/templates/yacht-details.js" /* webpackChunkName: "component---src-templates-yacht-details-js" */),
  "component---src-templates-yachts-js": () => import("./../../../src/templates/yachts.js" /* webpackChunkName: "component---src-templates-yachts-js" */)
}

